<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>

        <template slot="header">
          <span class="mt-2">Addon : {{ details.code }} {{ details.description }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">Type</label>
              <b-form-select v-model="$v.details.addon_type.$model" :options="options.addon_type" :state="validateState('addon_type')"></b-form-select>
              <b-form-invalid-feedback v-if="!$v.details.addon_type.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">Rate or Premium?</label>
              <b-form-select v-model="$v.details.rate_or_premium.$model" :options="options.rate_or_premium" :state="validateState('rate_or_premium')"></b-form-select>
              <b-form-invalid-feedback v-if="!$v.details.rate_or_premium.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col cols="3">
              <label class="">Code</label>
              <b-input v-model="$v.details.addon_code.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('addon_code')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.addon_code.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="3" v-if="$v.details.rate_or_premium.$model === 1">
              <label class="">Premium</label>
              <b-input v-model="$v.details.addon_premium.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('addon_premium')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.addon_premium.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="3"  v-if="$v.details.rate_or_premium.$model === 2">
              <label class="">Rate</label>
              <b-input v-model="$v.details.addon_rate.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('addon_rate')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.addon_rate.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">Description</label>
              <b-input v-model="$v.details.description.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('description')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.description.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row  class="mb-3">

              <b-col cols="6">
                <label class="">Version</label>
                <b-input v-model="$v.details.version.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('version')"></b-input>
                <b-form-invalid-feedback v-if="!$v.details.version.required">This is a required field.</b-form-invalid-feedback>
              </b-col>

          </b-form-row>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="editAddon" class="float-right">Edit Addon</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, decimal } from "vuelidate/lib/validators";

  export default {
    name: 'AddonEdit',
    components: {
      Id,
    },
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'Edit'
      },
    },
    data: () => {
      return {
        details: {
          id: "",
          addon_code: "",
          addon_type: "",
          description: "",
          rate_or_premium: "",
          addon_premium: "",
          addon_rate: "",
          version: ""
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          addon_code: null,
          addon_type: null,
          rate_or_premium: null,
          addon_premium: null,
          description: null,
          addon_rate: null,
          version: null
        },
        options: {
          yes_no: [
            {
              value: "",
              text: 'Please select'
            },
            {
              value: true,
              text: 'Yes'
            },
            {
              value: false,
              text: 'No'
            }
          ]
        }
      }
    },
    validations() {

      return {
        details: {
          addon_type: {
            required
          },
          addon_code: {
            required
          },
          description: {
            required
          },
          rate_or_premium: {
            required
          },
          version: {
            required
          },
          addon_premium: {
            decimal,
            required: requiredIf(function () {
              return this.details.addon_type === 1
            })
          },
          addon_rate: {
            decimal,
            required: requiredIf(function () {
              return this.details.addon_type === 2
            })
          }
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.details[name]) {
          const {$dirty, $error} = this.$v.details[name];
          return $dirty ? !$error : null;
        }
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getAddon() {
        let url = '';

        url = '/addons/' + this.$route.params.id + '/?product=' + this.$route.params.product

        axios.get(url).then(
          response => {
            this.details = response.data;
            this.options = response.data.options;
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      editAddon() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.details));

          let url = '';

          url = '/addons/' + this.$route.params.id + '/?product=' + this.$route.params.product;

          axios.put(url, post_data).then(
            response => (
              this.data = response.data
            )
          ).then(response => {
            response

            let alert = {
              type: 'success',
              text: 'Addon : ' + this.details.addon_code + ' ' + this.details.description + ' was updated successfully!'
            };

            this.$store.commit(
              'setGlobalAlert',
              alert
            )
            this.$router.replace('/system-config/products/' + this.$route.params.product + '/addons/')

        }).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){

                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      this.getAddon()
      this.$v.details.$reset();
      this.$v.$reset()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
